// import node modules ----------------------------------------------------------
import { React, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

// import node modules ----------------------------------------------------------
import{
    Box,
    Divider,
    useMediaQuery,
    useTheme,
} from '@mui/material'

// import icons -----------------------------------------------------------------
import { 
    Facebook1F, 
    Facebook2F, 
    Instagram1F, 
    Instagram2F, 
    LinkedIn1F, 
    LinkedIn2F, 
    X1F, 
    X2F,
    Facebook1,
    Facebook2,
    Instagram1,
    Instagram2,
    Linkedin1,
    Linkedin2,
    X1,
    X2,
    SisirLogo,
    SisirLogoWhite,
    MakeInIndiaLogo 
} from '../../assetsv1/Assets' 

// create a list ----------------------------------------------------------------
const pageList = [
    {
        title: 'Home',
        pageName: '/',
        description: 'shows the home page',
    },
    {
        title: 'About Us',
        pageName: '/aboutus',
        description: 'shows the about-us page',
    },
    {
        title: 'Technology Solutions',
        pageName: '/technology',
        description: 'shows the technology page',
    },
    {
        title: 'Blog',
        pageName: 'blog',
        description: 'shows the blog page',
    },
    {
        title: 'Careers',
        pageName: '/careers',
        description: 'shows the career page',
    },

]

// function to get the vaertical scrolling zero ---------------------------------
const scrollToTop =() => {
    window.scrollTo(0,0)
  }


// url --------------------------------------------------------------------------
// const careerLink = 'https://docs.google.com/forms/d/14isc_21pmB3yKuxonQ5R6Co09q1LVg8C2ip6mW4h2r0/viewform?edit_requested=true'
const contactLink = 'https://docs.google.com/forms/d/14isc_21pmB3yKuxonQ5R6Co09q1LVg8C2ip6mW4h2r0/viewform?edit_requested=true'

// create a footer that will appear on every page -------------------------------
export default function Footer(){

    // const theme & media-query ------------------------------------------------
    const theme = useTheme()
    // const isMatch = useMediaQuery(theme.breakpoints.down('md'))
    const isMatchToTablet = useMediaQuery('(max-width: 1080px)')
    const isMatchToTablet2 = useMediaQuery('(max-width: 960px)')
    const isMatchToMobileLg = useMediaQuery('(max-width: 720px)')
    const isMobile= useMediaQuery(theme.breakpoints.down('md'))
    // const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))

    // state variables ----------------------------------------------------------
    const [buttonHovered, setButtonHovered] = useState(false)
    const [mediaIconHovered, setMediaIconHovered] = useState({})
    const [copyrightHovered, setCopyrightHovered] = useState(false)
    // const [trademarkHovered, setTrademarkHovered] = useState(false)
    const [activePage, setActivePage] = useState()

    // navigate-hook ------------------------------------------------------------
    const navigate = useNavigate()

    // use-location hook ------------------------------------------------------------
    const location = useLocation()

    // functions ----------------------------------------------------------------
    const handleClick=(item)=>{
        setActivePage('')
        if(item.pageName === "blog"){
            window.location.href = 'https://blog.sisirradar.com'
        }else{
            navigate(item.pageName)
        }
        scrollToTop()
      }
    // const handleCareerClick = (url) => {
    //     navigate('/')
    //     scrollToTop()
    // }
    const handleContactClick = (url) => {
        navigate('/')
        scrollToTop()
    }

    //   footer media icon list -----------------------------------------------------
    const footerMediaIconList = [
        
        {
            title: 'linked-in',
            iconVariants: {
                default: (location.pathname === ('/aboutus'))  ? LinkedIn1F : Linkedin2,
                active: (location.pathname === ('/aboutus'))  ? LinkedIn2F : Linkedin1,
            },
            link: 'https://www.linkedin.com/company/sisir-radar/'
        },
        {
            title: 'twitter',
            iconVariants: {
                default: (location.pathname === ('/aboutus'))  ? X1F : X2,
                active: (location.pathname === ('/aboutus'))  ? X2F : X1,
            },
            link: 'https://twitter.com/SisirRadar'
        },
        {
            title: 'facebook',
            iconVariants: {
                default: (location.pathname === ('/aboutus'))  ? Facebook1F : Facebook2,
                active: (location.pathname === ('/aboutus'))  ? Facebook2F : Facebook1,
            },
            link: 'https://www.facebook.com/sisir.radar'
        },
        {
            title: 'insta',
            iconVariants: {
                default: (location.pathname === ('/aboutus'))  ? Instagram1F : Instagram2,
                active: (location.pathname === ('/aboutus'))  ? Instagram2F : Instagram1,
            },
            link: 'https://www.instagram.com/sisir.radar/'
        }
    ]
 
    // style --------------------------------------------------------------------
    const footerContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        wrap: 'wrap',
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'center',
        ml: 'auto',
        mr: 'auto',
        cursor: 'default',
        backgroundColor: (location.pathname === ('/aboutus')) ? '#fff' : '#232C60' 
    }
    const footerChild1Style = {
        display: 'flex',
        flexDirection: isMatchToTablet2? 'column' : 'row',
        // flexDirection: isMatchToSmallDevice? 'column' : 'row', for 1/1
        // flexDirection: 'row',
        flexFlow: isMatchToTablet2? 'no-wrap' : 'row wrap',
        justifyContent: 'space-between',
        width: isMatchToTablet? '90%': '80%',
        borderBottom: (location.pathname === ('/aboutus')) ? 'solid 1px #232C60' : 'solid 1px #fff',
        paddingBottom: 2,
        mt: 3,
        // gap: isMatchToSmallDevice ? '10px' : '0px' // for 1/1 or 2/2
        gap: isMatchToTablet2 ? '10px' : '0px'
    }
    const footerGrndChildContainerStyle = {
        display: 'flex',
        // flexDirection: isMatchToTablet2? 'column' : 'row', //for 2/2
        flexDirection: isMatchToMobileLg? 'column' : 'row',
        flexFlow: isMatchToTablet2? 'no-wrap' : 'row wrap',
        justifyContent: 'space-between',
        // width: '40%',
        gap: isMatchToMobileLg ? '10px' : '0px',
        boxSizing: 'border-box',
        padding: isMatchToMobileLg? '0px 0px' : '0px 16px',
        // width: '100%',
        // marginBottom: '10px'
    }
    const footerChild2Style = {
        display: 'flex',
        wrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '80%',
        mt: 1,
        mb: 1,
        // borderTop: (location.pathname === ('/aboutus')) ? 'solid 1px #232C60' : 'solid 1px #fff'
    }
    const footerGrndChild1Style = {
        display: 'flex',
        // flexGrow: 1,
        flexShrink: '0',
        flexDirection: 'column',
        // fontFamily: 'prompt',
        gap: '8px',
        width: 'fit-content',
        padding: '8px 10px',
        minWidth: '180px',
        maxWidth: '300px'
    }
    const footerGrndChild2Style = {
        display: 'flex',
        gap: '10px',
        fontFamily: 'prompt'
    }
    
    // main layout --------------------------------------------------------------
    return(
        <>
        <Box sx={footerContainerStyle}>
            {
                isMatchToTablet2 ? (
                    <Box sx={footerChild1Style}>
                        <Box sx={footerGrndChildContainerStyle}>
                            <Box sx={footerGrndChild1Style} >
                                <img src={(location.pathname === ('/aboutus')) ? SisirLogo : SisirLogoWhite} alt="Sisir Radar" style={{ width: '150px'}}/>
                                <Box
                                    sx={{
                                        fontFamily:'NunitoSans_7pt-Light',
                                        fontSize: '14px',
                                        // fontWeight: '600',
                                        color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                    }}
                                >
                                    Sisir Radar Private Limited
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={footerGrndChildContainerStyle}>
                            <Box sx={footerGrndChild1Style} >
                                <Box 
                                    sx={{
                                        fontSize: '16px', 
                                        // fontWeight: '600', 
                                        color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff', 
                                        fontFamily: 'Oxanium-Bold',
                                    }}
                                >
                                    PAGES
                                </Box>
                                {
                                    pageList.slice(0, 4).map((item, index)=>(
                                        
                                        <Box 
                                            key={index} 
                                            sx={{
                                                fontFamily: activePage === item.pageName ? 'NunitoSans_7pt-Bold' : 'NunitoSans_7pt-Light',
                                                fontSize: '14px',
                                                // fontWeight: 600,
                                                color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                                
                                            }} 
                                            onMouseEnter={()=>{
                                                setActivePage(item.pageName)
                                            }}
                                            onMouseLeave={()=>{
                                                location.pathname === item.pageName ? setActivePage(item.pageName) : setActivePage('')
                                            }}
                                            onClick={()=>{handleClick(item)}}
                                        >
                                            {item.title}
                                        </Box>
                                        
                                    ))
                                }
                                {/* <a 
                                    href={careerLink} 
                                    rel='noreferrer'
                                    target='_blank' 
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Box
                                        sx={{
                                            fontFamily:'NunitoSans_7pt-Light',
                                            fontSize: '14px',
                                            // fontWeight: 600,
                                            color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                        }} 
                                        onMouseEnter={(event)=>{
                                            event.target.style.fontFamily = 'NunitoSans_7pt-Bold'
                                        }}
                                        onMouseLeave={(event)=>{
                                            event.target.style.fontFamily = 'NunitoSans_7pt-Light'
                                        }}
                                        onClick={()=>{handleCareerClick()}}
                                    > 
                                        Careers
                                    </Box>
                                </a> */}
                            </Box>
                        {/* </Box>
                        <Box sx={footerGrndChildContainerStyle}> */}
                        <Box sx={footerGrndChild1Style}>
                            <Box 
                                sx={{
                                    fontSize: '16px', 
                                    // fontWeight: '600', 
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                    fontFamily: 'Oxanium-Bold',}}
                            >
                                CONTACT
                            </Box>
                            <Box
                                sx={{
                                    fontFamily:'NunitoSans_7pt-Light',
                                    fontSize: '14px',
                                    // fontWeight: '600',
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                }}
                            >
                                Corporate Address: Rishi Tower, 4th Floor, Street No 0366, DH Block, Action Area 1, Newtown, Kolkata - 700156
                            </Box>
                            <Box
                                sx={{
                                    fontFamily:'NunitoSans_7pt-Light',
                                    fontSize: '14px',
                                    // fontWeight: '600',
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                }}
                            >
                                Regd Office: 1-1203 Casa Vyoma Tower, Vastrapur, Ahmedabad - 380015
                            </Box>
                            <Box
                                sx={{
                                    fontFamily:'NunitoSans_7pt-Light',
                                    fontSize: '14px',
                                    // fontWeight: '600',
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                }}
                            >
                                CIN: U32109GJ2021PTC125777
                            </Box>
                            <Box
                                sx={{
                                    fontFamily:'NunitoSans_7pt-Light',
                                    fontSize: '14px',
                                    // fontWeight: '600',
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                }}
                            >
                                <a href="mailto:namaskar@sisirradar.com" style={{textDecoration: 'none', color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff'}}>
                                    namaskar@sisirradar.com
                                </a>
                                {/* namaskar@sisirradar.com */}
                            </Box>
                            <Box 
                                sx={{
                                    fontSize: '14px', 
                                    // fontWeight: '600', 
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff', 
                                    fontFamily:'NunitoSans_7pt-Light'
                                }}
                            >
                                <a href="tel:+91-9147063635" style={{textDecoration: 'none', color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff'}}>
                                    +91-9147063635
                                </a>
                                {/* <a href="tel:+91-3348110668" style={{textDecoration: 'none', color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff'}}>
                                    +91-3348110668
                                </a> */}
                                {/* +91-3348110668 */}
                            </Box>
                            {/* <Box 
                                sx={{
                                    fontSize: '14px', 
                                    // fontWeight: '600', 
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff', 
                                    fontFamily:'NunitoSans_7pt-Light'
                                }}
                            >
                                Kolkata, India
                            </Box> */}
                        </Box>
                        <Box sx={footerGrndChild1Style}>
                            <Box 
                                sx={{
                                    fontSize: '16px', 
                                    // fontWeight: '600', 
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff', 
                                    fontFamily:'Oxanium-Bold'
                                }}
                            >
                                FOLLOW US
                            </Box>
                            <Box 
                                sx={{
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    gap: '16px'
                                }}
                            >
                                {
                                    footerMediaIconList.map((item, index)=>(
                                        <a 
                                            key={index} 
                                            href={item.link} 
                                            rel='noreferrer'
                                            target='_blank' 
                                            onMouseEnter={() => {
                                                if (!isMobile) {
                                                setMediaIconHovered({ ...mediaIconHovered, [index]: 'active' });
                                                }
                                            }}
                                            onMouseLeave={() => {
                                                if (!isMobile) {
                                                setMediaIconHovered({ ...mediaIconHovered, [index]: 'default' });
                                                }
                                            }}
                                            onTouchStart={()=>setMediaIconHovered({...mediaIconHovered, [index]: 'active'})}
                                            onTouchEnd={()=>{setMediaIconHovered({...mediaIconHovered, [index]: 'default'})}}
                                        >
                                            <img 
                                                src={item.iconVariants[mediaIconHovered[index] || 'default']} 
                                                alt='insta' 
                                                height={32}
                                            />
                                        </a>
                                    ))
                                }
                            </Box>
                            <Box sx={{display: 'flex', justifySelf: 'flex-start'}}>
                                <a 
                                    href={contactLink }
                                    rel='noreferrer'
                                    target='_blank'
                                    style={{textDecoration: 'none'}}
                                >
                                    <Box 
                                        sx={{
                                        backgroundColor: (buttonHovered)? ((location.pathname === ('/aboutus')) ? '#232C60' : '#fff') :'transparent', 
                                        color: (location.pathname === ('/aboutus'))  ? ((buttonHovered)? '#fff' : '#232C60') : ((buttonHovered) ? '#232C60' : '#fff'),
                                        border: (location.pathname === ('/aboutus')) ? 'solid #232C60' : 'solid #fff',
                                        fontSize: '20px',
                                        // fontWeight: '700',
                                        padding: '8px 12px',
                                        fontFamily:'Oxanium-Bold'
                                        }}
                                        onMouseEnter={()=>{
                                            if(!isMobile){
                                                setButtonHovered(true);
                                            }
                                        }}
                                        onMouseLeave={()=>{
                                            if(!isMobile){
                                                setButtonHovered(false);
                                            }
                                        }}
                                        onTouchStart={()=>setButtonHovered(true)}
                                        onTouchEnd={()=>setButtonHovered(false)}
                                        onClick={()=>{handleContactClick()}}
                                    >
                                        CONTACT NOW
                                    </Box>
                                </a>
                            </Box>
                        </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={footerChild1Style}>
                        <Box sx={footerGrndChild1Style}>
                            <img src={(location.pathname === ('/aboutus')) ? SisirLogo : SisirLogoWhite} alt="Sisir Radar" style={{ width: '150px'}}/>
                            <Box
                                sx={{
                                    fontFamily:'NunitoSans_7pt-Light',
                                    fontSize: '14px',
                                    // fontWeight: '600',
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                }}
                            >
                                Sisir Radar Private Limited
                            </Box>
                        </Box>
                        <Box sx={footerGrndChild1Style} >
                            <Box 
                                sx={{
                                    fontSize: '16px', 
                                    // fontWeight: '600', 
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff', 
                                    fontFamily: 'Oxanium-Bold',
                                }}
                            >
                                PAGES
                            </Box>
                            {
                                pageList.slice(0, 4).map((item, index)=>(
                                    <Box 
                                        key={index} 
                                        sx={{
                                            fontFamily: activePage === item.pageName ? 'NunitoSans_7pt-Bold' : 'NunitoSans_7pt-Light',
                                            fontSize: '14px',
                                            // fontWeight: 600,
                                            color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                            cursor: 'pointer'
                                        }} 
                                        onMouseEnter={()=>{
                                            setActivePage(item.pageName)
                                        }}
                                        onMouseLeave={()=>{
                                            // location.pathname === item.pageName ? setActivePage(item.pageName) : setActivePage('')
                                            setActivePage('')
                                        }}
                                        onClick={()=>{handleClick(item)}}
                                    >
                                        {item.title}
                                    </Box>
                                ))
                            }
                            {/* <a 
                                href={careerLink} 
                                rel='noreferrer'
                                target='_blank' 
                                style={{ textDecoration: 'none' }}
                            >
                                <Box
                                    sx={{
                                        fontFamily:'NunitoSans_7pt-Light',
                                        fontSize: '14px',
                                        // fontWeight: 600,
                                        color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                    }} 
                                    onMouseEnter={(event)=>{
                                        event.target.style.fontFamily = 'NunitoSans_7pt-Bold'
                                    }}
                                    onMouseLeave={(event)=>{
                                        event.target.style.fontFamily = 'NunitoSans_7pt-Light'
                                    }}
                                    onClick={()=>{handleCareerClick()}}
                                > 
                                    Careers
                                </Box>
                            </a> */}
                        </Box>
                        <Box sx={footerGrndChild1Style}>
                            <Box 
                                sx={{
                                    fontSize: '16px', 
                                    // fontWeight: '600', 
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                    fontFamily: 'Oxanium-Bold'
                                }}
                            >
                                CONTACT
                            </Box>
                            <Box
                                sx={{
                                    fontFamily:'NunitoSans_7pt-Light',
                                    fontSize: '14px',
                                    // fontWeight: '600',
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                }}
                            >
                                Corporate Address: Rishi Tower, 4th Floor, Street No 0366, DH Block, Action Area 1, Newtown, Kolkata - 700156
                            </Box>
                            <Box
                                sx={{
                                    fontFamily:'NunitoSans_7pt-Light',
                                    fontSize: '14px',
                                    // fontWeight: '600',
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                }}
                            >
                                Regd Office: 1-1203 Casa Vyoma Tower, Vastrapur, Ahmedabad - 380015
                            </Box>
                            <Box
                                sx={{
                                    fontFamily:'NunitoSans_7pt-Light',
                                    fontSize: '14px',
                                    // fontWeight: '600',
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                }}
                            >
                                CIN: U32109GJ2021PTC125777
                            </Box>
                            <Box
                                sx={{
                                    fontFamily:'NunitoSans_7pt-Light',
                                    fontSize: '14px',
                                    // fontWeight: '600',
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff',
                                }}
                            >
                                <a href="mailto:namaskar@sisirradar.com" style={{textDecoration: 'none', color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff'}}>
                                    namaskar@sisirradar.com
                                </a>
                                {/* namaskar@sisirradar.com */}
                            </Box>
                            <Box 
                                sx={{
                                    fontSize: '14px', 
                                    // fontWeight: '600', 
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff', 
                                    fontFamily:'NunitoSans_7pt-Light'
                                }}
                            >
                                <a href="tel:+91-9147063635" style={{textDecoration: 'none', color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff'}}>
                                    +91-9147063635
                                </a>
                            </Box>
                            {/* <Box 
                                sx={{
                                    fontSize: '14px', 
                                    // fontWeight: '600', 
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff', 
                                    fontFamily:'NunitoSans_7pt-Light'
                                }}
                            >
                                Kolkata, India
                            </Box> */}
                        </Box>
                        <Box sx={footerGrndChild1Style}>
                            <Box 
                                sx={{
                                    fontSize: '16px', 
                                    // fontWeight: '600', 
                                    color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff', 
                                    fontFamily:'Oxanium-Bold'
                                }}
                            >
                                FOLLOW US
                            </Box>
                            <Box 
                                sx={{
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    gap: '16px'
                                }}
                            >
                                {
                                    footerMediaIconList.map((item, index)=>(
                                        <a 
                                            key={index} 
                                            href={item.link} 
                                            rel='noreferrer'
                                            target='_blank' 
                                            onMouseEnter={() => {
                                                if (!isMobile) {
                                                setMediaIconHovered({ ...mediaIconHovered, [index]: 'active' });
                                                }
                                            }}
                                            onMouseLeave={() => {
                                                if (!isMobile) {
                                                setMediaIconHovered({ ...mediaIconHovered, [index]: 'default' });
                                                }
                                            }}
                                            onTouchStart={()=>setMediaIconHovered({...mediaIconHovered, [index]: 'active'})}
                                            onTouchEnd={()=>{setMediaIconHovered({...mediaIconHovered, [index]: 'default'})}}
                                        >
                                            <img 
                                                src={item.iconVariants[mediaIconHovered[index] || 'default']} 
                                                alt='insta' 
                                                height={32}
                                            />
                                        </a>
                                    ))
                                }
                            </Box>
                            <Box sx={{display: 'flex', justifySelf: 'flex-start'}}>
                                <a 
                                    href={contactLink }
                                    rel='noreferrer'
                                    target='_blank'
                                    style={{textDecoration: 'none'}}
                                >
                                    <Box 
                                        sx={{
                                        backgroundColor: (buttonHovered)? ((location.pathname === ('/aboutus')) ? '#232C60' : '#fff') :'transparent', 
                                        color: (location.pathname === ('/aboutus'))  ? ((buttonHovered)? '#fff' : '#232C60') : ((buttonHovered) ? '#232C60' : '#fff'),
                                        border: (location.pathname === ('/aboutus')) ? 'solid #232C60' : 'solid #fff',
                                        fontSize: '20px',
                                        // fontWeight: '700',
                                        padding: '8px 12px',
                                        fontFamily:'Oxanium-Bold'
                                        }}
                                        onMouseEnter={()=>{
                                            if(!isMobile){
                                                setButtonHovered(true);
                                            }
                                        }}
                                        onMouseLeave={()=>{
                                            if(!isMobile){
                                                setButtonHovered(false);
                                            }
                                        }}
                                        onTouchStart={()=>setButtonHovered(true)}
                                        onTouchEnd={()=>setButtonHovered(false)}
                                        onClick={()=>{handleContactClick()}}
                                    >
                                        CONTACT NOW
                                    </Box>
                                </a>
                            </Box>
                        </Box>
                    </Box>
                )
            }
            <Divider/>
            <Box sx={footerChild2Style}>
                <Box sx={footerGrndChild2Style}>
                    <Box 
                        sx={{
                            display: 'flex',
                            flexFlow: 'row wrap',
                            gap: '4px',
                            fontFamily: 'NunitoSans_7pt-Light',
                            // fontWeight: '600', 
                            color: (location.pathname === ('/aboutus')) ? '#232C60' : '#fff', 
                            fontSize: '14px',
                            textDecoration: copyrightHovered
                        }}
                        onMouseEnter={()=>setCopyrightHovered(true)}
                        onMouseLeave={()=>setCopyrightHovered(false)}
                    >
                        <span>© Copyright 2024 |</span>
                        <span>All rights reserved </span>
                    </Box>
                    {/* <Box 
                        sx={{
                            fontWeight: '600', 
                            color: '#232C60', 
                            fontSize: '12px',
                            textDecoration: trademarkHovered? 'underline' : 'none'
                        }}
                        onMouseEnter={()=>setTrademarkHovered(true)}
                        onMouseLeave={()=>setTrademarkHovered(false)}
                    >
                        Trademark Info
                    </Box> */}
                </Box>
                <Box sx={footerGrndChild2Style}>
                    <img src={MakeInIndiaLogo} alt="Make in India" height={40}/>
                </Box>
            </Box>
        </Box>
        </>
    )
}